import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { motion } from "framer-motion";

const SubPageBanner = ({ title }) => {
	return (
		<div className="subpage-banner d-flex flex-column align-items-center justify-content-center w-100 overflow-hidden">
			<div className="subpage-banner-overlay" />
			<div className="subpage-banner-content section-padding text-theme-white w-100 container-theme d-flex flex-column text-center align-items-center">
				<Breadcrumbs />
				<motion.h3
					initial={{ y: 30 }}
					whileInView={{ y: 1 }}
					transition={{ type: "spring" }}
				>
					{title}
				</motion.h3>
			</div>
		</div>
	);
};

export default SubPageBanner;
