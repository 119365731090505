import React from "react";
import { motion } from "framer-motion";

const SectionHeading = ({ headline, heading, align }) => {
	return (
		<motion.div
			initial={{ x: 100 }}
			whileInView={{ x: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }}
			className={`${
				align === "center"
					? "d-flex flex-column align-items-center text-center gap-3"
					: "d-flex flex-column align-items-start gap-3"
			} sectionheading`}
		>
			<div className="sectionheading-headline fw-bold">
				<div className="sectionheading-headline-shape" />
				{headline}
			</div>
			<h3>{heading}</h3>
		</motion.div>
	);
};

export default SectionHeading;
