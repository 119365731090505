import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import girl from "../../assets/images/background/team-detail-individual.png";
import StatisticsNumber from "../components/StatisticsNumber";

const TeamDetailSection = () => {
	return (
		<div className="cause-detail-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<Row className="w-100 g-4">
					<Col xs={12} lg={6} className="p-0">
						<img
							src={girl}
							alt="girl"
							className="w-100 h-100 object-fit-cover"
						/>
					</Col>
					<Col
						xs={12}
						lg={6}
						className="border-theme-light-gray d-flex flex-column align-items-start p-0"
					>
						<div className="w-100 bg-theme-light-gray d-flex align-items-center justify-content-between px-3 px-md-5 py-4">
							<div className="d-flex flex-column align-items-start gap-1">
								<h5 className="fw-bold">Naomi Django</h5>
								<p>Field Manager</p>
							</div>
							<div className="d-flex flex-column flex-md-row align-items-center gap-2">
								<p>Social Media:</p>
								<div className="d-flex align-items-center gap-2">
									<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
										<FaFacebookF />
									</div>
									<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
										<FaTwitter />
									</div>
									<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
										<FaInstagram />
									</div>
								</div>
							</div>
						</div>
						<div className="w-100 px-3 px-md-5 py-4 bg-theme-white d-flex flex-column gap-2 align-items-start gap-3">
							<p className="text-theme-gray">
								There are many variations of passages of Lorem Ipsum available,
								but the majority have suffered alteration in some form, by
								injected humor, randomized words which don't look even slightly
								believable. If you are going to use a passage of Lorem Ipsum,
								you need to be sure
							</p>
							<p>
								<span className="ff-head fw-bold">Expertise:</span> Strategy,
								Leadership
							</p>
							<p>
								<span className="ff-head fw-bold">Experience:</span> 20 Years
							</p>
							<p>
								<span className="ff-head fw-bold">Country:</span> USA
							</p>
							<p>
								<span className="ff-head fw-bold">Email:</span> info@example.com
							</p>
							<p>
								<span className="ff-head fw-bold">Phone:</span> (+44) 123 456
								7890
							</p>
						</div>
					</Col>
				</Row>
				<div className="d-flex flex-column align-items-start gap-2">
					<h5 className="fw-bold fs-3">Biography</h5>
					<p className="text-theme-gray">
						It is a long established fact that a reader will be distracted by
						the readable content of a page when looking at its layout. The point
						of using Lorem Ipsum is that it has a more-or-less normal
						distribution of letters, as opposed to using 'Content here, content
						here', making it look like readable English. Many desktop publishing
						packages and web page editors now use Lorem Ipsum as their default
						model text, and a search for 'lorem ipsum' will uncover many web
						sites still in their infancy. Various versions have evolved over the
						years, sometimes by accident, sometimes on purpose (injected humour
						and the like).
					</p>
					<p className="text-theme-gray">
						It is a long established fact that a reader will be distracted by
						the readable content of a page when looking at its layout. The point
						of using Lorem Ipsum is that it has a more-or-less normal
						distribution of letters, as opposed to using 'Content here, content
						here', making it look like readable English. Many desktop publishing
						packages and web page editors now use Lorem Ipsum as their default
						model text.
					</p>
				</div>
				<div className="d-flex flex-column align-items-start gap-2">
					<h5 className="fw-bold fs-3">Experience & Activities</h5>
					<p className="text-theme-gray">
						Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
						sunt eiusmod tempor incididunt labore dolore magna aliqua enim minim
						veniam quis nostrud exercitation ullamco laboris nisi ut aliquip
						isit dx ea commodo consequat.Lorem ipsum dolor sit amet, consectetur
						adipisicing elit, sed do sunt eiusmod tempfshtor incididunt labore
						dolore magna aliqua enim minim veniam. Lorem ipsum dolor sit amet,
						consectetur adipisicing elit.
					</p>
				</div>
				<div className="statistics w-100 d-flex flex-column flex-md-row flex-wrap gap-5 text-center justify-content-center bg-theme-dark align-items-center text-theme-white">
					<StatisticsNumber number="75%" heading="Donation" />
					<div className="divider-dot d-none d-lg-block" />
					<StatisticsNumber number="90%" heading="Medical Help" />
					<div className="divider-dot d-none d-lg-block" />
					<StatisticsNumber number="85%" heading="Poor Education" />
					<div className="divider-dot d-none d-lg-block" />
					<StatisticsNumber number="128+" heading="Homeless Help" />
				</div>
			</div>
		</div>
	);
};

export default TeamDetailSection;
