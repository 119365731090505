import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import VolunteerSection from "../shared/sections/VolunteerSection";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import ProjectSection from "../shared/sections/ProjectSection";

const Projects = () => {
	return (
		<Layout>
			<SubPageBanner title="Our charity projects" />
			<ProjectSection isListPage />
			<VolunteerSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Projects;
