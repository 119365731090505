import React from "react";

const Button = ({ variant, children, ...rest }) => {
	return (
		<button
			className={`${
				variant === "primary-animated"
					? "btn-theme-primary-animated"
					: variant === "primary-filled"
					? "btn-theme-primary-filled"
					: variant === "secondary-animated"
					? "btn-theme-secondary-animated"
					: variant === "secondary-filled"
					? "btn-theme-secondary-filled"
					: "btn-theme-primary-animated"
			} d-flex align-items-center gap-2 justify-content-center text-nowrap`}
			{...rest}
		>
			<span className="btn-dot"></span>
			<span className="btn-txt">{children}</span>
		</button>
	);
};

export default Button;
