import React from "react";
import { Col, Row } from "react-bootstrap";
import img from "../../assets/images/background/cause-details.png";
import contentImg1 from "../../assets/images/square-img/Photo-1.png";
import contentImg2 from "../../assets/images/square-img/Photo-2.png";
import { BsCheck2Circle } from "react-icons/bs";
import SupportCard from "../components/SupportCard";
import SideCardWidget from "../components/SideCardWidget";

const ProjectDetailSection = () => {
	return (
		<div className="cause-detail-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<Row className="w-100">
					<Col
						xs={12}
						lg={8}
						className="d-flex flex-column align-items-start gap-4"
					>
						<div className="cause-detail-section-img">
							<img src={img} alt="img" className="w-100 img-fluid" />
						</div>
						<div className="d-flex align-items-center justify-content-center gap-4 bg-theme-light-gray text-theme-gray py-3 px-4 w-100">
							<p>
								Date: 27 August, 2020 || Client: Logistic Company || Location:
								New York, USA
							</p>
						</div>
						<h5 className="fw-bold fs-3">Challenger</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure Latin wadgords, consectetur, from a Lorem
							Ipsum passage, and going through the cites of the word.
						</p>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure.
						</p>
						<h5 className="fw-bold fs-3">Result</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked.
						</p>
						<div className="d-flex flex-column align-items-start gap-2">
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Emergency response time is one hour or less guaranteed.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>We want you to be completely satisfied with our services.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									You deserve to have your questions answered in plain English.
								</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Our service philosophy is proactive, not reactive.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									We hire seasoned, professional technicians with at least 5-10
									years experience and usually many more.
								</p>
							</div>
						</div>
						<Row className="w-100">
							<Col xs={12} sm={6}>
								<img
									src={contentImg1}
									alt="img"
									className="img-fluid object-fit-cover"
								/>
							</Col>
							<Col xs={12} sm={6}>
								<img
									src={contentImg2}
									alt="img"
									className="img-fluid object-fit-cover"
								/>
							</Col>
						</Row>
					</Col>
					<Col
						xs={12}
						lg={4}
						className="d-flex flex-column align-items-start gap-4"
					>
						<SupportCard />
						<SideCardWidget title="Recent Projects" />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ProjectDetailSection;
