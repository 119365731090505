import React from "react";
import { Col, Row } from "react-bootstrap";
import Logo from "../../assets/images/logo/logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
	return (
		<div className="footer d-flex flex-column bg-theme-dark text-theme-white w-100">
			<div className="footer-top w-100 d-flex flex-column flex-xxl-row align-items-center justify-content-between container-theme text-theme-white">
				<Row className="w-100 gap-4 gap-md-0">
					<Col
						md={3}
						xxl={3}
						className="d-flex flex-column align-items-start gap-3"
					>
						<img src={Logo} alt="logo" className="img-fluid footer-logo" />
						<p>
							Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
							diska. Jinesade bel när feras redorade
						</p>
						<Link
							className="text-theme-primary text-decoration-underline"
							to={ROUTES.home}
						>
							Become A Volunteer
						</Link>
					</Col>
					<Col
						md={9}
						xxl={6}
						className="d-flex flex-column align-items-md-center gap-4"
					>
						<h5 className="fw-bold fs-3">Feel Free to Contact with us</h5>
						<div className="d-flex flex-column flex-md-row align-items-md-center gap-3 p-3 footer-contact">
							<div className="d-flex align-items-center gap-2 p-2">
								<BsTelephone className="fs-2 text-theme-primary" />
								<div>
									<h6>Call Us on</h6>
									<h4 className="fw-bold mt-1">+44 123 456 7890</h4>
								</div>
							</div>
							<div className="footer-contact--divider" />
							<div className="d-flex align-items-center gap-2 p-2">
								<HiOutlineMailOpen className="fs-2 text-theme-primary" />
								<div>
									<h6>Email Us on</h6>
									<h4 className="fw-bold mt-1">example@email.com</h4>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column items-md-center text-md-center">
							<h5 className="fw-bold fs-3">Our Location</h5>
							<p>1901 Thornridge Cir. Shiloh,Hawaii 81063</p>
						</div>
					</Col>
					<Col
						md={12}
						xxl={3}
						className="d-flex flex-column align-items-start gap-4"
					>
						<h5 className="fw-bold fs-3">Useful Links</h5>
						<div className="d-flex flex-column flex-md-row flex-wrap flex-xxl-column gap-3 text-theme-white align-items-md-start">
							<Link to={ROUTES.home} className="text-theme-white">
								Homepage
							</Link>
							<Link to={ROUTES.aboutUs} className="text-theme-white">
								About us
							</Link>
							<Link to={ROUTES.events} className="text-theme-white">
								Our Events
							</Link>
							<Link to={ROUTES.charityProjects} className="text-theme-white">
								Image Gallery
							</Link>
							<Link to={ROUTES.contactPage} className="text-theme-white">
								Contact Us
							</Link>
						</div>
					</Col>
				</Row>
			</div>
			<div className="footer-bottom container-theme text-theme-white py-4 w-100">
				<Row className="w-100 gap-4 gap-md-0 w-100 d-flex align-items-center justify-content-between">
					<Col
						md={3}
						className="d-flex gap-2 align-items-center flex-column flex-md-row"
					>
						<Link className="text-theme-white" to="">
							Terms & Conditions
						</Link>
						<Link className="text-theme-white" to="">
							Privacy Policy
						</Link>
					</Col>
					<Col md={6} className="text-center">
						Copyright © 2023. troothemes. All rights reserved.
					</Col>
					<Col
						md={3}
						className="d-flex justify-content-center justify-content-md-end"
					>
						<div className="d-flex align-items-center gap-2">
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaTwitter />
							</div>
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaInstagram />
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Footer;
