import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Causes",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.causes,
				name: "Causes",
			},
			{
				path: ROUTES.causeDetails,
				name: "Cause Detail",
			},
		],
	},
	{
		name: "Projects",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.charityProjects,
				name: "Charity Project",
			},
			{
				path: ROUTES.charityDetails,
				name: "Charity Detail",
			},
		],
	},
	{
		name: "Other Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.events,
				name: "Events",
			},
			{
				path: ROUTES.eventDetails,
				name: "Event Detail",
			},
			{
				path: ROUTES.ourTeam,
				name: "Our Team",
			},
			{
				path: ROUTES.teamDetails,
				name: "Team Details",
			},
			{
				path: ROUTES.faq,
				name: "FAQ",
			},
			{
				path: ROUTES.testimonial,
				name: "Testimonial",
			},
			{
				path: ROUTES.donate,
				name: "Donate",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
			{
				path: ROUTES.comingSoon,
				name: "Coming Soon",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogs,
				name: "Blogs",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactPage,
		name: "Contact Me",
	},
];
