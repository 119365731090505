import React from "react";
import SectionHeading from "../components/SectionHeading";
import { Col, Row } from "react-bootstrap";
import VolunteerCard from "../components/VolunteerCard";
import TeamImg1 from "../../assets/images/background/Team-member-1.jpg";
import TeamImg2 from "../../assets/images/background/Team-member-2.jpg";
import TeamImg3 from "../../assets/images/background/Team-member-3.jpg";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const VolunteerSection = ({ isListPage }) => {
	return (
		<div className="volunteer-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<SectionHeading
						align="center"
						headline="Our Great Volunteer"
						heading={<>Meet Our Awesome Volunteer</>}
					/>
				)}
				<Row className="w-100 g-3">
					<Col xs={12} md={6} lg={3}>
						<VolunteerCard
							img={TeamImg1}
							name="Jenny Lawrence"
							designation="Founder & CEO"
						/>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<VolunteerCard
							img={TeamImg2}
							name="Barbra Hathways"
							designation="Pet Instructor"
						/>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<VolunteerCard
							img={TeamImg3}
							name="Emilia Johnson"
							designation="Assistant"
						/>
					</Col>
					<Col xs={12} md={6} lg={3}>
						<VolunteerCard
							img={TeamImg1}
							name="Peter wough"
							designation="Field Manager"
						/>
					</Col>
					{isListPage && (
						<>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg1}
									name="Jenny Lawrence"
									designation="Founder & CEO"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg2}
									name="Barbra Hathways"
									designation="Pet Instructor"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg3}
									name="Emilia Johnson"
									designation="Assistant"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg1}
									name="Peter wough"
									designation="Field Manager"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg1}
									name="Jenny Lawrence"
									designation="Founder & CEO"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg2}
									name="Barbra Hathways"
									designation="Pet Instructor"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg3}
									name="Emilia Johnson"
									designation="Assistant"
								/>
							</Col>
							<Col xs={12} md={6} lg={3}>
								<VolunteerCard
									img={TeamImg1}
									name="Peter wough"
									designation="Field Manager"
								/>
							</Col>
						</>
					)}
				</Row>
				{!isListPage && (
					<Link to={ROUTES.ourTeam}>
						<Button variant="secondary-animated">View All Team +</Button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default VolunteerSection;
