import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";

const Testimonial = () => {
	return (
		<Layout>
			<SubPageBanner title="Our Awesome Clients Feedback" />
			<FeedbackSection isListPage />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Testimonial;
