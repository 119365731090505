import React from "react";
import img from "../assets/images/background/Coming-Soon.jpg";
import logo from "../assets/images/logo/Troo-Charity-Logo.png";
import sendImg from "../assets/images/icons/send-01.svg";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";

const ComingSoon = () => {
	return (
		<div className="coming-soon w-100 d-flex align-items-center px-0">
			<div className="w-100 w-md-50 coming-soon-content d-flex align-items-center justify-content-center">
				<div className="d-flex flex-column align-items-start p-5 gap-4">
					<img src={logo} alt="logo" className="img-fluid" />
					<p>New Website on The Way</p>
					<h1>Be Patient</h1>
					<p className="text-theme-gray">
						There are many variations of passages of Lorem Ipsum available, but
						the and majority have suffered alteration in some form, by injected
						humour, or randomised words which don't look even humour, or
						randomised slightly believable..
					</p>
					<div className="d-flex flex-column align-items-start gap-3 w-100">
						<p className="fs-4">Notify Me When it Launch</p>
						<div className="w-100 d-flex flex-column gap-2 flex-md-row">
							<input type="text" placeholder="Email" className="input" />
							<button className="bg-theme-dark text-nowrap d-flex align-items-center justify-content-center gap-2 text-theme-white border border-0 px-3 py-2 outline-none">
								<span>Notify Me</span>
								<img src={sendImg} alt="send" className="img-fluid" />
							</button>
						</div>
					</div>
					<div className="d-flex align-items-center gap-2">
						<p>Connect:</p>
						<div className="d-flex align-items-center gap-2">
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaFacebookF />
							</div>
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaTwitter />
							</div>
							<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
								<FaInstagram />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="px-0 w-100 w-md-50 d-none d-lg-block overflow-hidden coming-soon-img">
				<img src={img} className="w-100 h-100" alt="coming soon" />
			</div>
		</div>
	);
};

export default ComingSoon;
