import React from "react";
import StatisticsNumber from "../components/StatisticsNumber";
import { Form } from "react-bootstrap";
import Button from "../components/Button";

const DonateContent = () => {
	return (
		<div className="donateContent-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<StatisticsNumber number="Donation Form" />
				<form className="d-flex w-100 bg-theme-light-gray p-md-5 p-4">
					<div className="w-100 d-flex flex-column align-items-start bg-theme-white p-4 p-md-5 gap-5">
						<div className="d-flex flex-column align-items-start gap-3">
							<h4 className="fw-bold">Your Donation</h4>
							<div className="d-flex flex-wrap align-items-center gap-3">
								<div className="donateContent-amount">$50.00</div>
								<div className="donateContent-amount">$100.00</div>
								<div className="donateContent-amount">$150.00</div>
								<div className="donateContent-amount">$200.00</div>
								<div className="donateContent-amount">$500.00</div>
								<div className="donateContent-amount">Custom</div>
							</div>
						</div>
						<div className="donateContent-personal-info d-flex w-100 flex-column align-items-start gap-3">
							<h4 className="fw-bold">Personal Information</h4>
							<div className="donateContent-personal-info-grid w-100">
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>First Name</p>
									<input
										type="text"
										placeholder="First Name"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Last Name</p>
									<input
										type="text"
										placeholder="Last Name"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Email</p>
									<input
										type="email"
										placeholder="Email"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Phone</p>
									<input
										type="text"
										placeholder="Phone"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Address 1</p>
									<input
										type="text"
										placeholder="Address 1"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Address 2</p>
									<input
										type="text"
										placeholder="Address 2"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>City</p>
									<input
										type="text"
										placeholder="City"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>State</p>
									<input
										type="text"
										placeholder="State"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Country</p>
									<input
										type="text"
										placeholder="Country"
										className="input w-100"
									/>
								</div>
								<div className="d-flex gap-2 flex-column align-items-start w-100">
									<p>Zip Code</p>
									<input
										type="text"
										placeholder="Zip Code"
										className="input w-100"
									/>
								</div>
							</div>
						</div>
						<div className="d-flex flex-column align-items-start gap-3">
							<h4 className="fw-bold">Payment</h4>
							<p>Choose Your Payment Method</p>
							<div className="d-flex align-items-center gap-3">
								<div className="py-3 px-4 bg-theme-light-gray text-theme-dark">
									PayPal
								</div>
								<div className="py-3 px-4 bg-theme-light-gray text-theme-dark">
									Credit Card
								</div>
							</div>
							<Form.Select aria-label="Default select example">
								<option value="1">One Time</option>
							</Form.Select>
						</div>
						<Button variant="secondary-animated">Donate Now +</Button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default DonateContent;
