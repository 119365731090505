import React from "react";
import SectionHeading from "../components/SectionHeading";
import CauseCard from "../components/CauseCard";
import { Col, Row } from "react-bootstrap";
import img1 from "../../assets/images/background/Give-Education-To-Africa.jpg";
import img2 from "../../assets/images/background/Give-Them-Home.jpg";
import img3 from "../../assets/images/background/Save-Child-Africa.jpg";
import img4 from "../../assets/images/background/Save-Tree-Save-Earth.jpg";
import img5 from "../../assets/images/background/Health-and-Medical.jpg";
import img6 from "../../assets/images/background/Pure-Drinking-Water.jpg";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const CauseSection = ({ isListPage }) => {
	return (
		<div className="cause-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<SectionHeading
						align="center"
						headline="We Can Change World"
						heading={
							<>
								You Can Help Lots of People
								<br /> by Donating Little
							</>
						}
					/>
				)}
				<Row className="g-3">
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Living"
							img={img2}
							heading="Give Them Home"
							totalAmount="$17,00,000"
							raisedAmount="$8,30,000"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Child Care"
							img={img3}
							heading="Save Child Africa"
							totalAmount="$19,00,000"
							raisedAmount="$5,80,000"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Living"
							img={img4}
							heading="Save Tree, Save Earth"
							totalAmount="$17,00,000"
							raisedAmount="$8,30,000"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Health Care"
							img={img5}
							heading="Health and Medical"
							totalAmount="$19,00,000"
							raisedAmount="$5,80,000"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Pure Water"
							img={img6}
							heading="Pure Drinking Water"
							totalAmount="$19,00,000"
							raisedAmount="$5,80,000"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<CauseCard
							badge="Education"
							img={img1}
							heading="Give Education To Africa"
							totalAmount="$10,00,000"
							raisedAmount="$6.50,000"
						/>
					</Col>
					{isListPage && (
						<>
							<Col xs={12} md={6} lg={4}>
								<CauseCard
									badge="Child Care"
									img={img3}
									heading="Save Child Africa"
									totalAmount="$19,00,000"
									raisedAmount="$5,80,000"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<CauseCard
									badge="Living"
									img={img4}
									heading="Save Tree, Save Earth"
									totalAmount="$17,00,000"
									raisedAmount="$8,30,000"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<CauseCard
									badge="Health Care"
									img={img5}
									heading="Health and Medical"
									totalAmount="$19,00,000"
									raisedAmount="$5,80,000"
								/>
							</Col>
						</>
					)}
				</Row>
				{!isListPage && (
					<Link to={ROUTES.causes}>
						<Button variant="secondary-animated">View All Causes + </Button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default CauseSection;
