import React from "react";
import errorImg from "../assets/images/background/404-Image.png";
import Button from "../shared/components/Button";
const Error = () => {
	return (
		<div className="error-page d-flex flex-column align-items-center justify-content-center px-4 py-5">
			<div className="d-flex flex-column align-items-center gap-4">
				<img src={errorImg} alt="404" className="img-fluid" />
				<h2 className="text-center">Oh No! The Page is totally Lost</h2>
				<p className="text-theme-gray text-center">
					Sorry, the page you were looking for doesn't exist or has been moved.
				</p>
				<Button variant="secondary-animated">Back to Homepage</Button>
			</div>
		</div>
	);
};

export default Error;
