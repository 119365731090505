import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import CauseDetailSection from "../shared/sections/CauseDetailSection";

const CauseDetails = () => {
	return (
		<Layout>
			<SubPageBanner title="Give Education To Africa" />
			<CauseDetailSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default CauseDetails;
