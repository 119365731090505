import React from "react";
import { BsPlus } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const ProjectCard = ({ img, heading, categories }) => {
	return (
		<motion.div
			initial={{ x: -40 }}
			whileInView={{ x: 1 }}
			transition={{ type: "spring" }}
			className="project-card w-100 overflow-hidden"
		>
			<Link to={ROUTES.charityDetails}>
				<img src={img} alt="img" className="project-card-img w-100 img-fluid" />
			</Link>
			<motion.div
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				transition={{ type: "spring", duration: 1 }}
				className="project-card-wrapper p-4 flex-column align-items-start justify-content-between"
			>
				<Link
					to={ROUTES.charityDetails}
					className="project-card-wrapper-plus-btn bg-theme-primary text-theme-white p-2 fs-2 rounded-circle d-flex align-items-center justify-content-center"
				>
					<BsPlus />
				</Link>
				<div className="text-theme-white d-flex flex-column gap-3">
					<p>{categories}</p>
					<Link
						to={ROUTES.charityDetails}
						className="fs-3 text-theme-white ff-head fw-bold"
					>
						{heading}
					</Link>
				</div>
			</motion.div>
		</motion.div>
	);
};

export default ProjectCard;
