import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { ROUTES } from "./shared/constants/routes";
import About from "./pages/About";
import Causes from "./pages/Causes";
import CauseDetails from "./pages/CauseDetails";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import Teams from "./pages/Teams";
import Teamdetails from "./pages/Teamdetails";
import FAQ from "./pages/FAQ";
import Testimonial from "./pages/Testimonial";
import Events from "./pages/Events";
import EventDetails from "./pages/EventDetails";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import Donate from "./pages/Donate";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import ComingSoon from "./pages/ComingSoon";

function App() {
	return (
		<div className="wrapper">
			<BrowserRouter>
				<Routes>
					<Route path={ROUTES.home} element={<Home />} />
					<Route path={ROUTES.aboutUs} element={<About />} />
					<Route path={ROUTES.causes} element={<Causes />} />
					<Route path={ROUTES.causeDetails} element={<CauseDetails />} />
					<Route path={ROUTES.charityProjects} element={<Projects />} />
					<Route path={ROUTES.charityDetails} element={<ProjectDetails />} />
					<Route path={ROUTES.ourTeam} element={<Teams />} />
					<Route path={ROUTES.teamDetails} element={<Teamdetails />} />
					<Route path={ROUTES.faq} element={<FAQ />} />
					<Route path={ROUTES.testimonial} element={<Testimonial />} />
					<Route path={ROUTES.events} element={<Events />} />
					<Route path={ROUTES.eventDetails} element={<EventDetails />} />
					<Route path={ROUTES.blogs} element={<Blogs />} />
					<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
					<Route path={ROUTES.donate} element={<Donate />} />
					<Route path={ROUTES.contactPage} element={<Contact />} />
					<Route path={ROUTES.errorPage} element={<Error />} />
					<Route path={ROUTES.comingSoon} element={<ComingSoon />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
