import React from "react";
import { BiSolidQuoteAltRight } from "react-icons/bi";
import { motion } from "framer-motion";

const FeedbackCard = ({ img, profileName, profileDesignation, desc }) => {
	return (
		<motion.div
			initial={{ y: 50 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring" }}
			className="feedback-card d-flex bg-theme-white flex-column w-100 p-4 gap-4"
		>
			<div className="w-100 d-flex align-items-center justify-content-between gap-3">
				<div className="d-flex align-items-center gap-2">
					<img
						src={img}
						alt="img"
						className="rounded-circle border-theme-primary"
					/>
					<div className="d-flex flex-column align-items-start">
						<h5 className="text-theme-dark fw-bolder ">{profileName}</h5>
						<p className="text-theme-gray">{profileDesignation}</p>
					</div>
				</div>
				<div className="d-flex align-items-center justify-content-center text-theme-dark bg-theme-primary p-3 fs-3">
					<BiSolidQuoteAltRight />
				</div>
			</div>
			<p className="text-theme-gray">{desc}</p>
		</motion.div>
	);
};

export default FeedbackCard;
