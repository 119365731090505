import React from "react";
import { Col, Row } from "react-bootstrap";
import SectionHeading from "../components/SectionHeading";
import Image1 from "../../assets/images/background/why-us-hero-image-1.jpg";
import Image2 from "../../assets/images/background/why-us-hero-image-2.jpg";
import Button from "../components/Button";
import { BsPatchCheck, BsTelephone } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const WhyChooseUsSection = () => {
	return (
		<div className="whyus d-flex flex-column align-items-center justify-content-center bg-theme-white w-100">
			<Row className="section-padding text-theme-secondary gap-4 gap-lg-0 w-100 container-theme align-items-center">
				<Col xs={12} lg={6}>
					<motion.div
						initial={{ x: -100 }}
						whileInView={{ x: 1 }}
						transition={{ duration: 1, type: "spring", stiffness: 100 }}
						className="d-flex flex-column align-items-start gap-3"
					>
						<SectionHeading
							headline="Why Choose Us"
							heading="We’ve funded 1,20,00 charity projects for 20M people around the world."
						/>
						<span className="text-theme-gray">
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humor, or rando missed.
						</span>
						<div className="d-flex text-theme-dark fs-4 flex-column gap-3 align-items-start">
							<div className="d-flex align-items-center gap-1">
								<BsPatchCheck />
								<h6 className="fw-bold fs-5">
									To overcome the level of poverty
								</h6>
							</div>
							<div className="d-flex align-items-center gap-1">
								<BsPatchCheck />
								<h6 className="fw-bold fs-5">To make the nation literate</h6>
							</div>
							<div className="d-flex align-items-center gap-1">
								<BsPatchCheck />
								<h6 className="fw-bold fs-5">
									To make the world a better place
								</h6>
							</div>
							<div className="d-flex align-items-center gap-1">
								<BsPatchCheck />
								<h6 className="fw-bold fs-5">To make the poor people happy</h6>
							</div>
						</div>
						<Link to={ROUTES.donate}>
							<Button variant="secondary-animated">Donate Us Now + </Button>
						</Link>
					</motion.div>
				</Col>
				<Col
					xs={12}
					lg={6}
					className="whyus-right mt-5 d-flex flex-column align-items-center mt-lg-0"
				>
					<div className="d-flex gap-5">
						<div>
							<img src={Image1} alt="img" className="img-fluid" />
						</div>
						<div>
							<img src={Image2} alt="img1" className="img-fluid" />
						</div>
					</div>
					<motion.div
						initial={{ y: -30 }}
						whileInView={{ y: 1 }}
						transition={{
							type: "spring",
							stiffness: 100,
						}}
						className="whyus-right-experience-circle d-flex flex-column align-items-center justify-content-center text-center bg-theme-dark py-3 px-4 gap-2"
					>
						<motion.div className="whyus-right-experience-circle-icon d-flex align-items-center justify-content-center text-theme-primary border-theme-white rounded-circle p-3 fs-5 bg-theme-dark">
							<BsTelephone />
						</motion.div>
						<p className="fs-6 text-theme-white">Call Us today</p>
						<h6 className="fs-5 fw-bolder text-theme-primary">
							+44 123 456 7890
						</h6>
					</motion.div>
				</Col>
			</Row>
		</div>
	);
};

export default WhyChooseUsSection;
