import React from "react";
import { Col, Row } from "react-bootstrap";
import img from "../../assets/images/background/cause-details.png";
import contentImg1 from "../../assets/images/square-img/Photo-1.png";
import contentImg2 from "../../assets/images/square-img/Photo-2.png";
import { BsCheck2Circle } from "react-icons/bs";
import SupportCard from "../components/SupportCard";
import SideCardWidget from "../components/SideCardWidget";

const CauseDetailSection = () => {
	return (
		<div className="cause-detail-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<Row className="w-100">
					<Col
						xs={12}
						lg={8}
						className="d-flex flex-column align-items-start gap-4"
					>
						<div className="cause-detail-section-img">
							<img src={img} alt="img" className="w-100 img-fluid" />
						</div>
						<div className="w-100 d-flex align-items-center gap-3 justify-content-between">
							<h4 className="fs-2 fw-bold">Give Education To Africa</h4>
							<div className="d-flex align-items-start gap-4 bg-theme-light-gray py-3 px-4">
								<div className="d-flex flex-column align-items-start gap-2">
									<h5 className="fw-bold fs-3">48</h5>
									<p>Donner</p>
								</div>
								<div className="d-flex flex-column align-items-start gap-2">
									<h5 className="fw-bold fs-3">$8,223.00</h5>
									<p>Donated</p>
								</div>
							</div>
						</div>
						<p className="text-theme-gray">
							There are many variations of passages of Lorem Ipsum available,
							but the majority have suffered alteration in some form, by
							injected humour, or randomised words which don’t look even
							slightly believable. If you are going to use a passage of Lorem
							Ipsum, you need to be sure there isn’t anything
						</p>
						<div className="d-flex flex-column gap-3 align-items-center w-100">
							<div className="theme-progress w-100 bg-theme-light-gray">
								<div className="theme-progress-active bg-theme-primary" />
							</div>
							<div className="w-100 fw-bold fs-5 text-theme-dark d-flex align-items-center justify-content-between">
								<p>30% Donated</p>
								<p>Goal: $6,50,000</p>
							</div>
						</div>
						<h5 className="fw-bold fs-3">
							How did this come to our attention?
						</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure Latin wadgords, consectetur, from a Lorem
							Ipsum passage, and going through the cites of the word.
						</p>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure.
						</p>
						<h5 className="fw-bold fs-3">Lorem Ipsum it Just Dummy Text</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked.
						</p>
						<div className="d-flex flex-column align-items-start gap-2">
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Emergency response time is one hour or less guaranteed.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>We want you to be completely satisfied with our services.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									You deserve to have your questions answered in plain English.
								</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Our service philosophy is proactive, not reactive.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									We hire seasoned, professional technicians with at least 5-10
									years experience and usually many more.
								</p>
							</div>
						</div>
						<Row className="w-100">
							<Col>
								<img
									src={contentImg1}
									alt="img"
									className="w-100 h-100 img-fluid"
								/>
							</Col>
							<Col>
								<img
									src={contentImg2}
									alt="img"
									className="w-100 h-100 img-fluid"
								/>
							</Col>
						</Row>
					</Col>
					<Col
						xs={12}
						lg={4}
						className="d-flex flex-column align-items-start gap-4"
					>
						<SupportCard />
						<SideCardWidget title="Urgent Causes" isDonation />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default CauseDetailSection;
