import React from "react";
import SectionHeading from "../components/SectionHeading";
import Button from "../components/Button";
import img2 from "../../assets/images/background/Give-Them-Home.jpg";
import img3 from "../../assets/images/background/Save-Child-Africa.jpg";
import img4 from "../../assets/images/background/Save-Tree-Save-Earth.jpg";
import { Col, Row } from "react-bootstrap";
import ProjectCard from "../components/ProjectCard";
import Blog4 from "../../assets/images/square-img/Blogs-9.png";
import Blog5 from "../../assets/images/square-img/Blogs-8.png";
import Blog6 from "../../assets/images/square-img/Blogs-7.png";
import Blog7 from "../../assets/images/square-img/Blogs-6.png";
import Blog8 from "../../assets/images/square-img/Blogs-5.png";
import Blog9 from "../../assets/images/square-img/Blogs-9.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";
const ProjectSection = ({ isListPage }) => {
	return (
		<div className="project-section d-flex flex-column align-items-center justify-content-center w-100">
			{!isListPage && <div className="project-section-top-banner w-100" />}
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<div className="d-flex w-100 flex-column flex-md-row align-items-start align-items-md-center justify-content-between gap-3">
						<SectionHeading
							headline="Our charity projects"
							heading={
								<>
									We’ve Done Lot’s Of
									<br /> Awesome Projects
								</>
							}
						/>
						<Link to={ROUTES.charityProjects}>
							<Button variant="secondary-animated">View Full Galary + </Button>
						</Link>
					</div>
				)}
				<Row className="w-100 g-3">
					<Col xs={12} md={6} lg={4}>
						<ProjectCard
							categories="Food, Home"
							img={img2}
							heading="Give Them Home"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<ProjectCard
							categories="Food"
							img={img3}
							heading="John D. Clause VP Of Philanthropy"
						/>
					</Col>
					<Col xs={12} md={6} lg={4}>
						<ProjectCard
							categories="Peace"
							img={img4}
							heading="CI $1 Million Donations"
						/>
					</Col>
					{isListPage && (
						<>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Food, Home"
									img={Blog4}
									heading="Give Them Home"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Food"
									img={Blog5}
									heading="John D. Clause VP Of Philanthropy"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Peace"
									img={Blog6}
									heading="CI $1 Million Donations"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Food, Home"
									img={Blog7}
									heading="Give Them Home"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Food"
									img={Blog8}
									heading="John D. Clause VP Of Philanthropy"
								/>
							</Col>
							<Col xs={12} md={6} lg={4}>
								<ProjectCard
									categories="Peace"
									img={Blog9}
									heading="CI $1 Million Donations"
								/>
							</Col>
						</>
					)}
				</Row>
			</div>
		</div>
	);
};

export default ProjectSection;
