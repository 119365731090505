import React from "react";
import { Accordion } from "react-bootstrap";
import { motion } from "framer-motion";

const Accordian = ({ heading, desc }) => {
	return (
		<motion.div
			initial={{ y: 14, scale: 0.9, opacity: 0.5 }}
			whileInView={{ y: 1, scale: 1, opacity: 1 }}
			transition={{
				duration: 1,
				type: "spring",
				stiffness: 100,
			}}
		>
			<Accordion defaultActiveKey="0" className="theme-accordian">
				<Accordion.Item>
					<Accordion.Header className="">{heading}</Accordion.Header>
					<Accordion.Body>{desc}</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</motion.div>
	);
};

export default Accordian;
