import React from "react";
import Button from "./Button";

const CommentForm = () => {
	return (
		<div className="w-100 d-flex flex-column gap-3 align-items-start text-theme-dark">
			<h3>Comments (0)</h3>
			<p className="fs-5">Submit a Comment</p>
			<div className="d-flex gap-2 flex-column align-items-start w-100">
				<p>Name</p>
				<input type="text" placeholder="Name" className="input w-100" />
			</div>
			<div className="d-flex gap-2 flex-column align-items-start w-100">
				<p>Email</p>
				<input type="text" placeholder="Email" className="input w-100" />
			</div>
			<div className="d-flex gap-2 flex-column align-items-start w-100">
				<p>Message</p>
				<textarea placeholder="Message" className="input w-100"></textarea>
			</div>
			<div className="form-check">
				<input
					className="form-check-input"
					type="checkbox"
					value=""
					id="flexCheckChecked"
				/>
				<label className="form-check-label" for="flexCheckChecked">
					Save My Name, Email, and Website in This Browser for the Next Time
					Comment.
				</label>
			</div>
			<Button variant="secondary-animated">Submit Now +</Button>
		</div>
	);
};

export default CommentForm;
