import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const Breadcrumbs = () => {
	const location = useLocation();
	let nav = location.pathname
		?.replace(/\?.+/, "")
		?.split("/")
		?.filter((x) => x);

	return (
		<div aria-label="breadcrumb text-theme-white">
			<ul className="breadcrumb">
				<li className="breadcrumb-item">
					<Link to={ROUTES.home} className="text-theme-white">
						Home
					</Link>
				</li>
				{nav.map((x, i) => (
					<li key={x} className="breadcrumb-item">
						<Link
							to={`/${x}`}
							className={`text-capitalize ${
								i === nav.length - 1
									? "text-theme-primary pe-none"
									: "text-theme-white"
							}`}
						>
							{x?.replace(/-/gi, " ")}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Breadcrumbs;
