import React from "react";
import img1 from "../../assets/images/square-img/Photo-5.png";

const SideCardWidget = ({ title, isDonation, isEvent }) => {
	return (
		<div className="w-100 d-flex flex-column align-items-center">
			<div className="p-3 bg-theme-dark text-theme-white w-100">
				<h4>{title}</h4>
			</div>
			<div className="px-3 d-flex flex-column w-100 align-items-center border-theme-dark">
				<div className="widget-card d-flex align-items-center gap-3 py-3">
					<div className="w-25">
						<img src={img1} alt="img" className="w-100 img-fluid" />
					</div>
					<div className="d-flex gap-2 w-75 flex-column align-items-start">
						<h5 className="fw-bold">Save Child Africa</h5>
						{isDonation && (
							<p className="fs-6 text-theme-gray">
								<span className="text-theme-primary fw-bold">$10,00,000</span>{" "}
								of <span className="text-theme-dark fw-bold">$6.50,000</span>{" "}
								raised
							</p>
						)}
						{isEvent && (
							<p className="fs-6 text-theme-gray">Date: 22 December, 2022</p>
						)}
					</div>
				</div>
				<div className="divider" />
				<div className="widget-card d-flex align-items-center gap-3 py-3">
					<div className="w-25">
						<img src={img1} alt="img" className="w-100 img-fluid" />
					</div>
					<div className="d-flex gap-2 w-75 flex-column align-items-start">
						<h5 className="fw-bold">Save Child Africa</h5>
						{isDonation && (
							<p className="fs-6 text-theme-gray">
								<span className="text-theme-primary fw-bold">$10,00,000</span>{" "}
								of <span className="text-theme-dark fw-bold">$6.50,000</span>{" "}
								raised
							</p>
						)}
						{isEvent && (
							<p className="fs-6 text-theme-gray">Date: 22 December, 2022</p>
						)}
					</div>
				</div>
				<div className="divider" />
				<div className="widget-card d-flex align-items-center gap-3 py-3">
					<div className="w-25">
						<img src={img1} alt="img" className="w-100 img-fluid" />
					</div>
					<div className="d-flex gap-2 w-75 flex-column align-items-start">
						<h5 className="fw-bold">Save Child Africa</h5>
						{isDonation && (
							<p className="fs-6 text-theme-gray">
								<span className="text-theme-primary fw-bold">$10,00,000</span>{" "}
								of <span className="text-theme-dark fw-bold">$6.50,000</span>{" "}
								raised
							</p>
						)}
						{isEvent && (
							<p className="fs-6 text-theme-gray">Date: 22 December, 2022</p>
						)}
					</div>
				</div>
				<div className="divider" />
				<div className="widget-card d-flex align-items-center gap-3 py-3">
					<div className="w-25">
						<img src={img1} alt="img" className="w-100 img-fluid" />
					</div>
					<div className="d-flex gap-2 w-75 flex-column align-items-start">
						<h5 className="fw-bold">Save Child Africa</h5>
						{isDonation && (
							<p className="fs-6 text-theme-gray">
								<span className="text-theme-primary fw-bold">$10,00,000</span>{" "}
								of <span className="text-theme-dark fw-bold">$6.50,000</span>{" "}
								raised
							</p>
						)}
						{isEvent && (
							<p className="fs-6 text-theme-gray">Date: 22 December, 2022</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideCardWidget;
