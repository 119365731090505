import React from "react";
import { motion } from "framer-motion";

const StatisticsNumber = ({ number, heading }) => {
	return (
		<motion.div
			initial={{ y: 40 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring" }}
			className="d-flex flex-column align-items-center gap-2"
		>
			<div className="statistics-number d-flex flex-column align-items-center justify-content-center text-center">
				<h1>{number}</h1>
				{heading ? (
					<h3 className="text-nowrap">{number}</h3>
				) : (
					<h4 className="text-nowrap fw-bold">{number}</h4>
				)}
			</div>
			{heading && <p className="fs-5">{heading}</p>}
		</motion.div>
	);
};

export default StatisticsNumber;
