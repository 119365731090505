import React from "react";
import Button from "./Button";
import { motion } from "framer-motion";

const CauseCard = ({ badge, img, heading, totalAmount, raisedAmount }) => {
	return (
		<motion.div
			initial={{ y: 40 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring" }}
			className="cause-card w-100 d-flex flex-column"
		>
			<div className="cause-card-thumbnail w-100 h-100 overflow-hidden">
				<h6 className="cause-card-thumbnail-badge p-2 text-theme-dark bg-theme-primary">
					{badge}
				</h6>
				<motion.img
					src={img}
					alt="africa"
					className="w-100 h-100 img-fluid"
					initial={{ scale: 1 }}
					whileHover={{ scale: 1.1 }}
					transition={{ type: "spring" }}
				/>
			</div>
			<div className="cause-card-content d-flex p-4 flex-column gap-3 align-items-start border-theme-primary-opacity-50">
				<h4 className="fs-3">{heading}</h4>
				<div>
					<span className="text-theme-primary fs-5 fw-bold">{totalAmount}</span>{" "}
					of{" "}
					<span className="text-theme-dark fs-5 fw-normal">{raisedAmount}</span>{" "}
					raised
				</div>
				<p className="text-theme-gray">
					Lorem psum dolor sit amet, and it is tetur adipiscing elit. Libero
					turpis blandit blandit mauris aliquam
				</p>
				<Button variant="secondary-animated">Donate Now +</Button>
			</div>
		</motion.div>
	);
};

export default CauseCard;
