import React from "react";
import SectionHeading from "../components/SectionHeading";
import { Col, Row } from "react-bootstrap";
import BlogCard from "../components/BlogCard";
import Button from "../components/Button";
import Blog1 from "../../assets/images/background/blog-1.jpg";
import Blog2 from "../../assets/images/background/blog-2.jpg";
import Blog3 from "../../assets/images/background/blog-3.jpg";
import Blog4 from "../../assets/images/square-img/Blogs-9.png";
import Blog5 from "../../assets/images/square-img/Blogs-8.png";
import Blog6 from "../../assets/images/square-img/Blogs-7.png";
import Blog7 from "../../assets/images/square-img/Blogs-6.png";
import Blog8 from "../../assets/images/square-img/Blogs-5.png";
import Blog9 from "../../assets/images/square-img/Blogs-9.png";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const BlogSection = ({ isListPage }) => {
	return (
		<div className="blog-section d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<SectionHeading
						align="center"
						headline="Latest News & Blogs"
						heading={
							<>
								What’s New in Non Profit <br />
								Area to Know
							</>
						}
					/>
				)}
				<Row className="w-100 g-4">
					<Col xs={12} sm={12} lg={6} xl={4}>
						<BlogCard
							img={Blog1}
							title="50,000 of us went on Climate
Strike – now what?"
						/>
					</Col>
					<Col xs={12} sm={12} lg={6} xl={4}>
						<BlogCard
							img={Blog2}
							title="Turning your emergency 
donation into instant aid"
						/>
					</Col>
					<Col xs={12} sm={12} lg={6} xl={4}>
						<BlogCard
							img={Blog3}
							title="Our 10 Favorite Climate Strike
protest signs"
						/>
					</Col>
					{isListPage && (
						<>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog4}
									title="A new tool to give even more
directly"
								/>
							</Col>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog5}
									title="Charitable Donation Deadlines
for 2022"
								/>
							</Col>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog6}
									title="Inflation is worst for people in
extreme poverty"
								/>
							</Col>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog7}
									title="Why giving directly still means
giving well"
								/>
							</Col>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog8}
									title="Study: refugees build
independence with cash aid"
								/>
							</Col>
							<Col xs={12} sm={12} lg={6} xl={4}>
								<BlogCard
									img={Blog9}
									title="The neglected climate change
solution"
								/>
							</Col>
						</>
					)}
				</Row>
				{!isListPage && (
					<Link to={ROUTES.blogs}>
						<Button variant="secondary-animated">Read All Blogs +</Button>
					</Link>
				)}
			</div>
		</div>
	);
};

export default BlogSection;
