import React from "react";
import SectionHeading from "../components/SectionHeading";
import { Col, Row } from "react-bootstrap";
import Accordian from "../components/Accordian";
import { Link } from "react-router-dom";
import imgShape from "../../assets/images/shape/charity-background.png";
import { ROUTES } from "../constants/routes";

const FAQSection = ({ isListPage }) => {
	return (
		<div className="faq-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			{!isListPage && (
				<img
					src={imgShape}
					alt="shape"
					className="bottom-img img-fluid w-100"
				/>
			)}

			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<SectionHeading
						align="center"
						headline="How can we help?"
						heading={<>Frequently asked questions</>}
					/>
				)}
				<Row className="g-4 w-100">
					<Col xs={12} md={6} className="d-flex flex-column gap-3">
						<Accordian
							heading="Q: What is a public charity?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: Is this charity I am interested in legitimate?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: When will my charity’s rating be updated?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: How can we supply you with a newer Form 990?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: What is the Nonprofit Portal, and how does it work?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						{isListPage && (
							<>
								<Accordian
									heading="Q: What is a public charity?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: Is this charity I am interested in legitimate?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: When will my charity’s rating be updated?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: How can we supply you with a newer Form 990?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: What is the Nonprofit Portal, and how does it work?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
							</>
						)}
					</Col>
					<Col xs={12} md={6} className="d-flex flex-column gap-3">
						<Accordian
							heading="Q: Which charities do you evaluate?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: How did the charity I reviewed get the rating it ?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: How can I contact a charity listed on our website?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: How did my charity receive this rating"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						<Accordian
							heading="Q: What are the responsibilities of the Trustees?"
							desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
						/>
						{isListPage && (
							<>
								<Accordian
									heading="Q: Which charities do you evaluate?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: How did the charity I reviewed get the rating it ?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: How can I contact a charity listed on our website?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: How did my charity receive this rating"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
								<Accordian
									heading="Q: What are the responsibilities of the Trustees?"
									desc="A: Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock."
								/>
							</>
						)}
					</Col>
				</Row>
				<p className="fs-5 fw-medium text-theme-gray">
					Didn’t Find Your Answer?{" "}
					{isListPage ? (
						<Link to={ROUTES.contactPage} className="fs-4 underline text-theme-dark fw-bold">
							{" "}
							Contact With Us{" "}
						</Link>
					) : (
						<Link to={ROUTES.faq} className="fs-4 underline text-theme-dark fw-bold">
							{" "}
							View More FAQ’s{" "}
						</Link>
					)}
				</p>
			</div>
		</div>
	);
};

export default FAQSection;
