import React from "react";
import Button from "./Button";

const SupportCard = () => {
	return (
		<div className="support-card gap-3 d-flex flex-column align-items-start text-theme-white p-4 w-100">
			<h3 className="fs-1">
				Support for <br />
				African Children
			</h3>
			<div className="">
				<p>Not a problem. Call us now</p>
				<h4 className="text-theme-primary fw-bold">+44 123 456 7890</h4>
			</div>
			<div className="">
				<p>Or email us :</p>
				<h4 className="text-theme-primary fw-bold">contact@troothemes.com</h4>
			</div>
			<Button variant="primary-animated">Donate Now +</Button>
		</div>
	);
};

export default SupportCard;
