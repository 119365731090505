import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import TeamDetailSection from "../shared/sections/TeamDetailSection";

const TeamDetails = () => {
	return (
		<Layout>
			<SubPageBanner title="Meet Our Great Volunteers" />
			<TeamDetailSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default TeamDetails;
