import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import VolunteerSection from "../shared/sections/VolunteerSection";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import CauseSection from "../shared/sections/CauseSection";

const Causes = () => {
	return (
		<Layout>
			<SubPageBanner title="Share your love for community" />
			<CauseSection isListPage/>
			<VolunteerSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Causes;
