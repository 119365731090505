import React from "react";

const SideCategoryWidget = () => {
	return (
		<div className="w-100 d-flex flex-column align-items-center">
			<div className="p-4 bg-theme-dark text-theme-white w-100">
				<h4>Categories</h4>
			</div>
			<div className="px-4 d-flex flex-column w-100 align-items-center border-theme-dark">
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Charity</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Donation</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Homeless</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Clean Water</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Education</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Provety</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Food</span>
					</div>
					<p>10</p>
				</div>
				<div className="divider" />
				<div className="d-flex py-3 align-items-center w-100 justify-content-between">
					<div className="d-flex align-items-center gap-2">
						<div className="list-circle" /> <span>Child Education</span>
					</div>
					<p>10</p>
				</div>
			</div>
		</div>
	);
};

export default SideCategoryWidget;
