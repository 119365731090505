import React from "react";

const SideTagWidget = () => {
	return (
		<div className="w-100 d-flex flex-column align-items-center">
			<div className="p-4 bg-theme-dark text-theme-white w-100">
				<h4>Tags</h4>
			</div>
			<div className="px-4 d-flex flex-column w-100 align-items-center border-theme-dark">
				<div className="d-flex py-3 flex-wrap align-items-center gap-2 w-100">
					<div className="tag px-3 py-1 bg-theme-dark text-theme-white">
						Charity
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Education
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Donation
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Homeless
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Coaching
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Health Care
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Events
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Poor
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Children
					</div>
					<div className="tag px-3 py-1 bg-theme-light-gray text-theme-dark">
						Orphanage
					</div>
				</div>
			</div>
		</div>
	);
};

export default SideTagWidget;
