import React from "react";
import StatisticsNumber from "./StatisticsNumber";

const Statistics = () => {
	return (
		<div className="statistics w-100 d-flex flex-column flex-md-row flex-wrap gap-5 text-center justify-content-center bg-theme-dark align-items-center text-theme-white">
			<StatisticsNumber number="20" heading="Billion Fund Rise" />
			<div className="divider-dot d-none d-lg-block" />
			<StatisticsNumber number="3k" heading="Happy Volunteers" />
			<div className="divider-dot d-none d-lg-block" />
			<StatisticsNumber number="9k" heading="Monthly Doners" />
			<div className="divider-dot d-none d-lg-block" />
			<StatisticsNumber number="918" heading="Successful Projects" />
		</div>
	);
};

export default Statistics;
