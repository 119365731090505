import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import AboutSection from "../shared/sections/AboutSection";
import ServiceSection from "../shared/sections/ServiceSection";
import VideoBanner from "../shared/sections/VideoBanner";
import VolunteerSection from "../shared/sections/VolunteerSection";
import FeedbackSection from "../shared/sections/FeedbackSection";
import WhyChooseUsSection from "../shared/sections/WhyChooseUsSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";

const About = () => {
	return (
		<Layout>
			<SubPageBanner title="We believe that we can save life" />
			<AboutSection />
			<ServiceSection />
			<VideoBanner />
			<VolunteerSection />
			<FeedbackSection />
			<WhyChooseUsSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default About;
