import React from "react";
import SectionHeading from "../components/SectionHeading";
import Button from "../components/Button";
import EventCard from "../components/EventCard";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const WebinarSection = ({ isListPage }) => {
	return (
		<div className="webinar d-flex flex-column align-items-center justify-content-center w-100">
			{!isListPage && <div className="webinar-top-banner" />}
			<div className="section-padding text-theme-white w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<div className="d-flex w-100 flex-column flex-md-row align-items-start align-items-md-center justify-content-between gap-3">
						<SectionHeading
							headline="Help With Featured Cause"
							heading={
								<>
									Join upcoming Events &
									<br /> Webinars
								</>
							}
						/>
						<Link to={ROUTES.events}>
							<Button variant="primary-filled">View All Events + </Button>
						</Link>
					</div>
				)}
				<div className="d-flex flex-column gap-3 align-items-center text-theme-secondary">
					<EventCard />
					<EventCard />
					<EventCard />
					<EventCard />
					{isListPage && (
						<>
							<EventCard />
							<EventCard />
							<EventCard />
							<EventCard />
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default WebinarSection;
