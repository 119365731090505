import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import VolunteerSection from "../shared/sections/VolunteerSection";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";

const Teams = () => {
	return (
		<Layout>
			<SubPageBanner title="Meet Our Great Volunteers" />
			<VolunteerSection isListPage />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Teams;
