import React from "react";
import { BsCalendarEvent, BsPerson } from "react-icons/bs";
import { motion } from "framer-motion";
import { ROUTES } from "../constants/routes";
import { Link } from "react-router-dom";

const BlogCard = ({ img, title }) => {
	return (
		<motion.div
			initial={{ y: 30, opacity: 0.7 }}
			whileInView={{ y: 1 }}
			whileHover={{ opacity: 1 }}
			transition={{ type: "spring" }}
			className="blogCard d-flex flex-column w-100 items-center"
		>
			<div className="blogCard-img">
				<img src={img} alt="img" className="img-fluid" />
				<div className="blogCard-img-overlay align-items-center justify-content-center">
					<Link
						className="text-theme-dark bg-theme-primary p-3 d-flex align-items-center justify-content-center blogCard-plus rounded-circle fs-4 overflow-hidden"
						to={ROUTES.blogDetails}
					>
						+
					</Link>
				</div>
			</div>
			<div className="d-flex flex-column align-items-start bg-theme-white p-4 gap-3">
				<div className="blogCard-date-name d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between text-theme-dark p-3 w-100 gap-3">
					<div className="d-flex text-theme-dark align-items-center gap-2">
						<BsCalendarEvent className="fs-5" />
						<p>April 7, 2019</p>
					</div>
					<div className="d-flex align-items-center gap-2">
						<BsPerson className="fs-5" />
						<p>Johnathan Joe</p>
					</div>
				</div>
				<Link to={ROUTES.blogDetails}>
					<h5 className="fw-bold text-theme-dark fs-4">{title}</h5>
				</Link>
			</div>
		</motion.div>
	);
};

export default BlogCard;
