import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const VolunteerCard = ({ img, name, designation }) => {
	return (
		<motion.div
			initial={{ x: 40 }}
			whileInView={{ x: 1 }}
			transition={{ type: "spring" }}
			className="volunteer-card w-100 overflow-hidden"
		>
			<div className="volunteer-card-img overflow-hidden">
				<Link to={ROUTES.teamDetails}>
					<img src={img} alt="img" className="w-100 h-full img-fluid" />
				</Link>
				<motion.div
					initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ type: "spring" }}
					className="volunteer-card-img-wrapper p-4 flex-column align-items-center"
				>
					<div className="d-flex align-items-center gap-2">
						<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
							<FaFacebookF />
						</div>
						<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
							<FaTwitter />
						</div>
						<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
							<FaInstagram />
						</div>
					</div>
				</motion.div>
			</div>
			<div className="w-100 bg-theme-white border-theme-primary-opacity-50 text-center p-3 shadow-sm d-flex flex-column align-items-center gap-1">
				<h5 className="text-theme-dark">{name}</h5>
				<p className="text-theme-gray">{designation}</p>
			</div>
		</motion.div>
	);
};

export default VolunteerCard;
