import React from "react";
import Button from "../components/Button";
import SectionHeading from "../components/SectionHeading";
import { Col, Row } from "react-bootstrap";
import FeedbackCard from "../components/FeedbackCard";
import Avatar1 from "../../assets/images/avatar/avatar-1.jpg";
import Avatar2 from "../../assets/images/avatar/avatar-2.jpg";
import Avatar3 from "../../assets/images/avatar/avatar-3.jpg";
import Avatar4 from "../../assets/images/avatar/avatar-4.jpg";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const FeedbackSection = ({ isListPage }) => {
	return (
		<div className="feedback-section d-flex flex-column align-items-center justify-content-center w-100">
			{!isListPage && <div className="feedback-section-top-banner" />}
			<div className="section-padding text-theme-white w-100 container-theme d-flex flex-column align-items-center gap-5">
				{!isListPage && (
					<div className="d-flex w-100 flex-column flex-md-row align-items-start align-items-md-center justify-content-between gap-3">
						<SectionHeading
							fontWhite
							headline="What Volunteers Say About Us"
							heading={
								<>
									Trusted by Some Famous
									<br /> Volunteer Feedback
								</>
							}
						/>
						<Link to={ROUTES.testimonial}>
							<Button variant="primary-filled">View All Feedback + </Button>
						</Link>
					</div>
				)}
				<Row className="w-100 g-3">
					<Col xs={12} md={6}>
						<FeedbackCard
							img={Avatar1}
							profileName="Stephen King"
							profileDesignation="Video camera operator"
							desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
						/>
					</Col>
					<Col xs={12} md={6}>
						<FeedbackCard
							img={Avatar2}
							profileName="Elmer K. Huitt"
							profileDesignation="Marketing coordinator"
							desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
						/>
					</Col>
					<Col xs={12} md={6}>
						<FeedbackCard
							img={Avatar3}
							profileName="Jessica Alaba"
							profileDesignation="Perianesthesia nurse"
							desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
						/>
					</Col>
					<Col xs={12} md={6}>
						<FeedbackCard
							img={Avatar4}
							profileName="Leonid Boholyubov"
							profileDesignation="Balanced Fortune Specialist"
							desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
						/>
					</Col>
					{isListPage && (
						<>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar1}
									profileName="Stephen King"
									profileDesignation="Video camera operator"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar2}
									profileName="Elmer K. Huitt"
									profileDesignation="Marketing coordinator"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar3}
									profileName="Jessica Alaba"
									profileDesignation="Perianesthesia nurse"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar4}
									profileName="Leonid Boholyubov"
									profileDesignation="Balanced Fortune Specialist"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar3}
									profileName="Jessica Alaba"
									profileDesignation="Perianesthesia nurse"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
							<Col xs={12} md={6}>
								<FeedbackCard
									img={Avatar4}
									profileName="Leonid Boholyubov"
									profileDesignation="Balanced Fortune Specialist"
									desc="There are many variations of passages of Lorems Ipsum available, but the majority have suffered Lorems alteration in some form, by injected humour, or randomized words which and it is don’t look even slightly believable. If you are going."
								/>
							</Col>
						</>
					)}
				</Row>
			</div>
		</div>
	);
};

export default FeedbackSection;
