import Button from "../components/Button";
import MouseScroll from "../../assets/images/icons/mouse-scroll.svg";
import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const HeroSection = () => {
	return (
		<div className="herosection d-flex flex-column align-items-center justify-content-center bg-theme-secondary text-theme-white w-100">
			<div className="herosection-overlay text-theme-white w-100 d-flex flex-column align-items-center justify-content-center container-theme text-center gap-4">
				<p className="fw-bolder fs-5">give hope for Needy People</p>
				<motion.h1
					initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }}
				>
					Helping Each Other <br /> Can Make World Better
				</motion.h1>
				<motion.p
					initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }}
				>
					We Seek out world changers and difference makers around the globe,and
					<br />
					equip them to fulfill their unique purpose.
				</motion.p>
				<motion.div
					initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }}
					className="d-flex align-items-center gap-3 mt-4"
				>
					<Link to={ROUTES.donate}>
						<Button variant="primary-animated">Donate Now +</Button>
					</Link>
					<Link to={ROUTES.aboutUs}>
						<Button variant="secondary-filled">Learn More +</Button>
					</Link>
				</motion.div>
			</div>
			<motion.img
				src={MouseScroll}
				alt="scroll-down"
				className="img-fluid"
				initial={{ y: "-10" }}
				animate={{ y: 1 }}
				transition={{
					repeat: Infinity,
					repeatType: "reverse",
					duration: 1,
					ease: "linear",
					type: "spring",
					stiffness: 100,
				}}
			/>
		</div>
	);
};

export default HeroSection;
