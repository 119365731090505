import React from "react";
import SectionHeading from "../components/SectionHeading";
import { Col, Row } from "react-bootstrap";
import ServiceCard from "../components/ServiceCard";
import BallIcon from "../../assets/images/icons/ball.svg";
import GlobeIcon from "../../assets/images/icons/globe.svg";
import EducationHatIcon from "../../assets/images/icons/graduation-hat.svg";
import BriefcaseIcon from "../../assets/images/icons/briefcase.svg";
import Statistics from "../components/Statistics";

const ServiceSection = () => {
	return (
		<div className="service d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<SectionHeading
					align="center"
					headline="We Change Life & World"
					heading={
						<>
							We Believe that We can Save
							<br /> More Life with You
						</>
					}
				/>
				<Row className="w-100 g-4">
					<Col xs={12} md={6} xl={3}>
						<ServiceCard
							heading="Healthy Food"
							desc="There are many variations of passages of Lorem Ipsum."
							icon={BallIcon}
						/>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ServiceCard
							heading="Clean Water"
							desc="There are many variations of passages of Lorem Ipsum."
							icon={GlobeIcon}
						/>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ServiceCard
							heading="Education"
							desc="There are many variations of passages of Lorem Ipsum."
							icon={EducationHatIcon}
						/>
					</Col>
					<Col xs={12} md={6} xl={3}>
						<ServiceCard
							heading="Medical Care"
							desc="There are many variations of passages of Lorem Ipsum."
							icon={BriefcaseIcon}
						/>
					</Col>
				</Row>
				<Statistics />
			</div>
		</div>
	);
};

export default ServiceSection;
