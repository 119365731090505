import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import BlogSection from "../shared/sections/BlogSection";

const Blogs = () => {
	return (
		<Layout>
			<SubPageBanner title="What’s New in Non Profit Area to Know" />
			<BlogSection isListPage />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Blogs;
