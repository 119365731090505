import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import WebinarSection from "../shared/sections/WebinarSection";

const Events = () => {
	return (
		<Layout>
			<SubPageBanner title="Join upcoming Events & Webinars" />
			<WebinarSection isListPage />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Events;
