import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import ContactUsContent from "../shared/sections/ContactUsContent";

const Contact = () => {
	return (
		<Layout>
			<SubPageBanner title="Get in Touch" />
			<ContactUsContent />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default Contact;
