import React, { useState } from "react";
import Button from "../components/Button";
import { FaPlay } from "react-icons/fa";
import Shape from "../../assets/images/shape/aero-line-shape.png";
import { motion } from "framer-motion";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const VideoBanner = () => {
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return (
		<div className="videobanner d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-white w-100 container-theme align-items-center row g-5">
				<motion.div
					initial={{ x: -40 }}
					whileInView={{ x: 1 }}
					transition={{ type: "spring" }}
					className="d-flex gap-4 col-12 col-lg-8 order-2 order-lg-1 flex-column align-items-start"
				>
					<h3 className="text-capitalize fs-1">
						It’s so challenging to find a good team to do great things. But we
						can provide you the best one.
					</h3>
					<p>
						There are many variations of passages of Lorem Ipsum available, but
						the majority have suffered alteration in some form, by injected
						humor, or rando missed.
					</p>
					<Link to={ROUTES.ourTeam}>
						<Button variant="primary-animated">Become A Volunteer +</Button>
					</Link>
				</motion.div>
				<div className="videobanner-play d-flex flex-column col-12 col-lg-4 order-1 order-lg-2 align-items-center gap-4">
					<img src={Shape} alt="shape" className="videobanner-play-shape" />
					<p>Watch Intro Video</p>
					<motion.div
						initial={{ scale: 0.8 }}
						animate={{ scale: 1 }}
						transition={{
							repeat: Infinity,
							repeatType: "reverse",
							duration: 1,
							ease: "linear",
							type: "spring",
							stiffness: 100,
						}}
						onClick={handleShow}
						className="videobanner-play-icon d-flex align-items-center justify-content-center"
					>
						<FaPlay />
					</motion.div>
				</div>
			</div>
			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Body>
					<iframe
						width="100%"
						height="400"
						src="https://www.youtube.com/embed/DUfXdcpEfMs"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default VideoBanner;
