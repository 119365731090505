import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import BlogDetailSection from "../shared/sections/BlogDetailSection";

const BlogDetails = () => {
	return (
		<Layout>
			<SubPageBanner title="50,000 of us went on Climate Strike – now what?" />
			<BlogDetailSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default BlogDetails;
