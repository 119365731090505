import React from "react";
import Layout from "../shared/components/Layout";
import HeroSection from "../shared/sections/HeroSection";
import AboutSection from "../shared/sections/AboutSection";
import ServiceSection from "../shared/sections/ServiceSection";
import VideoBanner from "../shared/sections/VideoBanner";
import CauseSection from "../shared/sections/CauseSection";
import ProjectSection from "../shared/sections/ProjectSection";
import VolunteerSection from "../shared/sections/VolunteerSection";
import FeedbackSection from "../shared/sections/FeedbackSection";
import WhyChooseUsSection from "../shared/sections/WhyChooseUsSection";
import FAQSection from "../shared/sections/FAQSection";
import WebinarSection from "../shared/sections/WebinarSection";
import BlogSection from "../shared/sections/BlogSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";

const Home = () => {
	return (
		<Layout>
			<HeroSection />
			<AboutSection />
			<ServiceSection />
			<VideoBanner />
			<CauseSection />
			<ProjectSection />
			<VolunteerSection />
			<FeedbackSection />
			<WhyChooseUsSection />
			<FAQSection />
			<WebinarSection />
			<BlogSection />
			<ClientBannerSection />
		</Layout>
	);
};

export default Home;
