import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../components/Button";
import phone from "../../assets/images/icons/phone-call-01.svg";
import locationImg from "../../assets/images/icons/marker-pin-03.svg";
import mail from "../../assets/images/icons/mail-05.svg";
import clock from "../../assets/images/icons/clock.svg";
import SectionHeading from "../components/SectionHeading";
import Img1 from "../../assets/images/brand/logo_01.png";
import Img2 from "../../assets/images/brand/logo_02.png";
import Img3 from "../../assets/images/brand/logo_03.png";
import Img4 from "../../assets/images/brand/logo_04.png";
import Img5 from "../../assets/images/brand/logo_05.png";
import Img6 from "../../assets/images/brand/logo_06.png";

const ContactUsContent = () => {
	return (
		<div className="donateContent-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<Row className="section-padding text-theme-secondary w-100 container-theme g-4">
				<Col xs={12} md={6} xl={3}>
					<div className="d-flex flex-column align-items-center gap-3">
						<div className="d-flex align-items-center justify-content-center p-4 border-theme-dark rounded-circle">
							<img src={phone} alt="phone" className="w-100 img-fluid" />
						</div>
						<div className="d-flex flex-column align-items-center gap-2">
							<p>Call Us On</p>
							<h5 className="text-theme-dark fw-bold">+44 132 456 7890</h5>
							<h5 className="text-theme-dark fw-bold">+44 987 654 3210</h5>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6} xl={3}>
					<div className="d-flex flex-column align-items-center gap-3">
						<div className="d-flex align-items-center justify-content-center p-4 border-theme-dark rounded-circle">
							<img src={mail} alt="phone" className="w-100 img-fluid" />
						</div>
						<div className="d-flex flex-column align-items-center gap-2 text-center">
							<p>Email Us</p>
							<h5 className="text-theme-dark fw-bold">
								contact@troothemes.com
							</h5>
							<h5 className="text-theme-dark fw-bold">info@trootheme.com</h5>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6} xl={3}>
					<div className="d-flex flex-column align-items-center gap-3">
						<div className="d-flex align-items-center justify-content-center p-4 border-theme-dark rounded-circle">
							<img src={locationImg} alt="phone" className="w-100 img-fluid" />
						</div>
						<div className="d-flex flex-column align-items-center gap-2 text-center">
							<p>Location</p>
							<h5 className="text-theme-dark fw-bold">
								4516 School Street Danbury,
							</h5>
							<h5 className="text-theme-dark fw-bold">CT, Canada</h5>
						</div>
					</div>
				</Col>
				<Col xs={12} md={6} xl={3}>
					<div className="d-flex flex-column align-items-center gap-3">
						<div className="d-flex align-items-center justify-content-center p-4 border-theme-dark rounded-circle">
							<img src={clock} alt="phone" className="w-100 img-fluid" />
						</div>
						<div className="d-flex flex-column align-items-center gap-2 text-center">
							<p>Opening Hours</p>
							<h5 className="text-theme-dark fw-bold">Mon-Sat: 10AM to 06PM</h5>
							<h5 className="text-theme-dark fw-bold">Sunday Closed</h5>
						</div>
					</div>
				</Col>
			</Row>
			<div className="section-padding bg-theme-light-gray text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<SectionHeading
					align="center"
					headline="would like to discuss?"
					heading={<>We’d love to hear from you.</>}
				/>
				<form className="w-100 d-flex flex-column align-items-start bg-theme-white p-4 p-md-5 gap-5">
					<div className="donateContent-personal-info d-flex w-100 flex-column align-items-start gap-3">
						<div className="donateContent-personal-info-grid w-100">
							<div className="d-flex gap-2 flex-column align-items-start w-100">
								<p>Your Name</p>
								<input
									type="text"
									placeholder="Your Name"
									className="input w-100"
								/>
							</div>
							<div className="d-flex gap-2 flex-column align-items-start w-100">
								<p>Phone</p>
								<input
									type="text"
									placeholder="Phone"
									className="input w-100"
								/>
							</div>
							<div className="d-flex gap-2 flex-column align-items-start w-100">
								<p>Email Address</p>
								<input
									type="email"
									placeholder="Email Address"
									className="input w-100"
								/>
							</div>

							<div className="d-flex gap-2 flex-column align-items-start w-100">
								<p>Subject</p>
								<input
									type="text"
									placeholder="Subject"
									className="input w-100"
								/>
							</div>
						</div>
						<div className="d-flex gap-2 flex-column align-items-start w-100">
							<p>Message</p>
							<textarea
								placeholder="Message"
								className="input w-100"
								rows={5}
							></textarea>
						</div>
					</div>
					<Button variant="secondary-animated">Submit Now +</Button>
				</form>
			</div>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.9963544183383!2d72.78470937580562!3d21.19230378049834!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04ddfbf486b7d%3A0x4efa1199e384241!2sTRooInbound%20Pvt.%20Ltd.%20%7CTop%20Rated%20WordPress%20and%20Expert%20Shopify%20Development%20Agency%20-%20Surat!5e0!3m2!1sen!2sin!4v1690183308051!5m2!1sen!2sin"
				width="100%"
				height="450"
				allowfullscreen=""
				loading="lazy"
				title="trooinbound"
				referrerpolicy="no-referrer-when-downgrade"
			></iframe>
			<div className="w-100 container-theme d-flex flex-column align-items-center gap-5 pb-5">
				<Row
					className="w-100 p-4 bg-theme-primary"
					style={{ marginTop: "-5%" }}
				>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img1} alt="img" />
					</Col>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img2} alt="img" />
					</Col>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img3} alt="img" />
					</Col>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img4} alt="img" />
					</Col>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img5} alt="img" />
					</Col>
					<Col xs={6} md={4} lg={2}>
						<img className="img-fluid w-100" src={Img6} alt="img" />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ContactUsContent;
