import React from "react";
import { Col, Row } from "react-bootstrap";
import img from "../../assets/images/background/cause-details.png";
import { BsCheck2Circle } from "react-icons/bs";
import SupportCard from "../components/SupportCard";
import SideCardWidget from "../components/SideCardWidget";
import Button from "../components/Button";

const EventDetailSection = () => {
	return (
		<div className="cause-detail-section bg-theme-white d-flex flex-column align-items-center justify-content-center w-100">
			<div className="section-padding text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5">
				<Row className="w-100">
					<Col
						xs={12}
						lg={8}
						className="d-flex flex-column align-items-start gap-4"
					>
						<div className="cause-detail-section-img">
							<img src={img} alt="img" className="w-100 img-fluid" />
						</div>
						<div className="d-flex flex-column flex-md-row w-100 align-items-start gap-3">
							<div className="d-flex w-100 flex-column align-items-start text-theme-dark">
								<h4 className="py-3 px-4 bg-theme-light-gray fw-bold w-100 border-bottom">
									Event Detail
								</h4>
								<div className="py-3 px-4 bg-theme-light-gray text-theme-gray w-100 d-flex flex-column align-items-start fs-6 gap-2">
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Date:
										</span>{" "}
										November 3
									</p>
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Time:
										</span>{" "}
										8:00 am - 5:00 pm
									</p>
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Category:
										</span>{" "}
										Event
									</p>
								</div>
							</div>
							<div className="d-flex w-100 flex-column align-items-start text-theme-dark">
								<h4 className="py-3 px-4 bg-theme-light-gray fw-bold w-100 border-bottom">
									Contact Detail
								</h4>
								<div className="py-3 px-4 bg-theme-light-gray text-theme-gray w-100 d-flex flex-column align-items-start fs-6 gap-2">
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Phone:
										</span>{" "}
										+44 123 466 7990
									</p>
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Email:
										</span>{" "}
										contact@troothemes.com
									</p>
									<p>
										<span className="ff-head text-theme-dark fw-bold">
											Location:
										</span>{" "}
										10/A, Hasting City, USA
									</p>
								</div>
							</div>
						</div>
						<h5 className="fw-bold fs-3">Challenger</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure Latin wadgords, consectetur, from a Lorem
							Ipsum passage, and going through the cites of the word.
						</p>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked up one
							of the more obscure.
						</p>
						<h5 className="fw-bold fs-3">Result</h5>
						<p className="text-theme-gray">
							Contrary to popular belief, Lorem Ipsum is not simply random text.
							It has roots in a piece of classical Latin literature from 45 BC,
							making it over 2000 years old. Richard McClintock, a Latin
							prgfjofessor at Hampden-Sydney College in Virginia, looked.
						</p>
						<div className="d-flex flex-column align-items-start gap-2">
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Emergency response time is one hour or less guaranteed.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>We want you to be completely satisfied with our services.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									You deserve to have your questions answered in plain English.
								</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>Our service philosophy is proactive, not reactive.</p>
							</div>
							<div className="d-flex align-items-center gap-2 text-theme-gray">
								<BsCheck2Circle className="fs-5" />
								<p>
									We hire seasoned, professional technicians with at least 5-10
									years experience and usually many more.
								</p>
							</div>
						</div>
						<Button variant="secondary-animated">Register Now +</Button>
					</Col>
					<Col
						xs={12}
						lg={4}
						className="d-flex flex-column align-items-start gap-4"
					>
						<SupportCard />
						<SideCardWidget title="Recent Events" isEvent />
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default EventDetailSection;
