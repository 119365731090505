import React from "react";
import Layout from "../shared/components/Layout";
import SubPageBanner from "../shared/sections/SubPageBanner";
import FeedbackSection from "../shared/sections/FeedbackSection";
import ClientBannerSection from "../shared/sections/ClientBannerSection";
import ProjectDetailSection from "../shared/sections/ProjectDetailSection";

const ProjectDetails = () => {
	return (
		<Layout>
			<SubPageBanner title="Free Education for African Children" />
			<ProjectDetailSection />
			<FeedbackSection />
			<ClientBannerSection noHead />
		</Layout>
	);
};

export default ProjectDetails;
