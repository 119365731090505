import React from "react";
import Button from "./Button";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const EventCard = () => {
	return (
		<motion.div
			initial={{ y: 50 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring" }}
			className="event-card bg-theme-white w-100 p-4 d-flex flex-column flex-lg-row align-items-start align-items-lg-center gap-3"
		>
			<div className="event-card-time d-flex align-items-center justify-content-lg-center">
				<div className="d-flex align-items-center gap-3">
					<h6 className="event-card-time-box text-center bg-theme-dark text-theme-white d-flex align-items-center justify-content-center p-2">
						01 Jan,
						<br /> 2022
					</h6>
					<div className="d-flex flex-column gap-1 align-items-start">
						<p>
							Time: <span>15:00 EST</span>
						</p>
						<p>
							Location: <span>New york, USA</span>
						</p>
						<p>
							Organized By: <span>TRoo Theme</span>
						</p>
					</div>
				</div>
			</div>
			<div className="vr d-none d-lg-block" />
			<div className="event-card-content d-flex align-items-center justify-content-center">
				<div className="d-flex flex-column gap-2 align-items-start px-lg-4">
					<h4 className="fs-3 fw-bold">
						For the Loving Country: Decade of Support
					</h4>
					<p className="text-theme-gray">
						Contrary to popular belief, Lorem Ipsum is not simply random text.
						It has roots in a piece of classical Latin literature.
					</p>
				</div>
			</div>
			<div className="vr d-none d-lg-block" />
			<div className="event-card-btn">
				<Link to={ROUTES.eventDetails}>
					<Button variant="secondary-animated">View Full Details +</Button>
				</Link>
			</div>
		</motion.div>
	);
};

export default EventCard;
