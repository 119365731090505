import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../components/Button";
import Image from "../../assets/images/background/image.png";
import HandIcon from "../../assets/images/icons/coins-hand.svg";
import UsersIcon from "../../assets/images/icons/users-03.svg";
import Image1 from "../../assets/images/background/image-1.png";
import SectionHeading from "../components/SectionHeading";
import shape1 from "../../assets/images/shape/about-img-back-shape.png";
import shape2 from "../../assets/images/shape/about-img-dot-shape.png";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const AboutSection = () => {
  return (
    <div className="about d-flex flex-column align-items-center justify-content-center bg-theme-white w-100">
      <Row className="section-padding text-theme-secondary gap-4 gap-lg-0 w-100 container-theme align-items-center">
        <Col xs={12} lg={6}>
          <motion.div
            initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="d-flex flex-column align-items-start gap-3"
          >
            <SectionHeading
              headline="Welcome to TRoo charity"
              heading="Help us end poverty for good. Start with a child."
            />
            <p className="fs-4">
              Helped fund{" "}
              <span className="text-theme-primary fw-bold">
                24,537 Projects
              </span>{" "}
              in{" "}
              <span className="text-theme-primary fw-bold">24 Countries</span>,
              Benefiting over{" "}
              <span className="text-theme-primary fw-bold">
                8.2 Million people
              </span>
              .
            </p>
            <span className="text-theme-gray">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humor, or rando missed.
            </span>
            <div className="w-100 d-flex flex-column flex-md-row my-4 align-items-start gap-3">
              <div className="d-flex align-items-start gap-3">
                <div className="about-list-icon">
                  <img alt="icon" src={HandIcon} />
                </div>
                <div className="d-flex flex-column gap-2 align-items-start">
                  <h6 className="fs-4 fw-bold">Quick Fundraising</h6>
                  <p className="text-theme-gray">
                    There are many variations of passages of Lorem Ipsum.
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-start gap-3">
                <div className="about-list-icon">
                  <img alt="icon" src={UsersIcon} />
                </div>
                <div className="d-flex flex-column gap-2 align-items-start">
                  <h6 className="fs-4 fw-bold">Our Mission</h6>
                  <p className="text-theme-gray">
                    There are many variations of passages of Lorem Ipsum.
                  </p>
                </div>
              </div>
            </div>
            <Link to={ROUTES.aboutUs}>
              <Button variant="secondary-animated">Learn More + </Button>
            </Link>
          </motion.div>
        </Col>
        <Col
          xs={12}
          lg={6}
          className="about-right mt-5 d-flex flex-column align-items-center mt-lg-0"
        >
          <img src={shape1} alt="img" className="img-fluid shape-right" />
          <img src={shape2} alt="img" className="img-fluid shape-left" />
          <div className="d-flex about-right-images">
            <div>
              <img src={Image} alt="img" className="img-fluid" />
            </div>
            <div>
              <img src={Image1} alt="img1" className="img-fluid" />
            </div>
          </div>
          <motion.div
            initial={{ scale: 0.9 }}
            whileInView={{ scale: 1 }}
            transition={{
              duration: 1,
              type: "spring",
              stiffness: 100,
              repeat: Infinity,
              repeatType: "reverse",
              ease: "linear",
            }}
            className="about-right-experience-circle d-flex flex-column align-items-center justify-content-center rounded-circle text-theme-secondary text-center bg-theme-primary"
          >
            <h5 className="fs-1 fw-bold">15+</h5>
            <h6>
              Years Of
              <br /> Experience{" "}
            </h6>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutSection;
