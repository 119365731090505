import React, { useState } from "react";
import Logo from "../../assets/images/logo/logo.svg";
import Accordion from "react-bootstrap/Accordion";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineMailOpen } from "react-icons/hi";
import { BiChevronDown } from "react-icons/bi";
import { SlMenu } from "react-icons/sl";
import { FaFacebookF, FaTwitter, FaInstagram } from "react-icons/fa";
import Button from "./Button";
import useWindowSize from "../hooks/useWindowSize";
import { Link, useLocation } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { NAV_ROUTES } from "../constants/navRoutes";
import { motion } from "framer-motion";
import { ROUTES } from "../constants/routes";

const Header = () => {
	const [width] = useWindowSize();
	const location = useLocation();
	const [show, setShow] = useState(false);
	return (
		<div className="header d-flex flex-column bg-theme-secondary text-theme-white w-100">
			<div className="py-3 w-100 d-flex align-items-center justify-content-between container-theme">
				<Link to={ROUTES.home}>
					<img src={Logo} alt="logo" className="img-fluid header-logo" />
				</Link>
				{width > 1023.98 && (
					<div className="d-flex align-items-center gap-4">
						<div className="d-flex align-items-center gap-2">
							<div className="p-2 fs-5 bg-theme-primary d-flex align-items-center justify-content-center">
								<BsTelephone />
							</div>
							<div>
								<h6>Call Us On</h6>
								<p className="fw-bold">+44 123 456 7890</p>
							</div>
						</div>
						<div className="d-flex align-items-center gap-2">
							<div className="p-2 fs-5 bg-theme-primary d-flex align-items-center justify-content-center">
								<HiOutlineMailOpen />
							</div>
							<div>
								<h6>Email Us</h6>
								<p className="fw-bold">info@troocharity.com</p>
							</div>
						</div>
						<Link to={ROUTES.donate}>
							<Button variant="primary-animated">Donate Now +</Button>
						</Link>
					</div>
				)}
				{width < 1023.98 && (
					<SlMenu className="fs-3" onClick={() => setShow(true)} />
				)}
			</div>

			{width > 1023.98 && (
				<div className="w-100 container-theme">
					<div className="header-menu w-100 d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center gap-3 gap-xl-4 gap-xxl-5">
							{NAV_ROUTES.map((route, i) => {
								if (!route?.isMultiple) {
									return (
										<Link
											key={i}
											to={route.path}
											className={`${
												location.pathname === route.path
													? "activeNav"
													: "header-link"
											} position-relative py-4 text-theme-white`}
										>
											{route.name}
										</Link>
									);
								} else {
									return (
										<div
											key={i}
											className="position-relative theme-dropdown py-4 text-theme-white"
										>
											<label
												tabIndex={0}
												className="d-flex align-items-center gap-1 h-100"
											>
												<span>{route.name}</span>
												<BiChevronDown className="text-theme-white fs-5" />
											</label>
											<motion.div
												initial={{ y: 14, scale: 0.9, opacity: 0.5 }}
												whileInView={{ y: 1, scale: 1, opacity: 1 }}
												transition={{
													duration: 1,
													type: "spring",
													stiffness: 100,
												}}
												className="position-absolute bg-theme-white flex-column theme-dropdown-menu"
											>
												{route.subPages.map((subPage, i) => {
													return (
														<div key={i} className="theme-dropdown-link py-2">
															<Link to={subPage.path} className="px-4">
																{subPage.name}
															</Link>
														</div>
													);
												})}
											</motion.div>
										</div>
									);
								}
							})}
						</div>
						<div className="d-flex align-items-center gap-2">
							<p>Social media:</p>
							<div className="d-flex align-items-center gap-2">
								<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
									<FaFacebookF />
								</div>
								<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
									<FaTwitter />
								</div>
								<div className="p-2 bg-theme-primary d-flex align-items-center justify-content-center rounded-circle">
									<FaInstagram />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}

			<Offcanvas
				show={show}
				onHide={() => setShow(false)}
				className="bg-theme-secondary text-theme-primary"
			>
				<Offcanvas.Header closeButton>
					<Offcanvas.Title>
						<img src={Logo} alt="logo" className="img-fluid sidebar-logo" />
					</Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body>
					<div className="d-flex flex-column align-items-start gap-4">
						{NAV_ROUTES.map((route, i) => {
							if (!route?.isMultiple) {
								return (
									<Link
										key={i}
										to={route.path}
										className={`${
											location.pathname === route.path && "activeNav"
										} position-relative text-theme-white header-link`}
									>
										{route.name}
									</Link>
								);
							} else {
								return (
									<Accordion className="sidebar-dropdown">
										<Accordion.Item eventKey={i}>
											<Accordion.Header>{route.name}</Accordion.Header>
											<Accordion.Body>
												{route.subPages.map((subPage, i) => {
													return (
														<div key={i} className="theme-dropdown-link py-2">
															<Link to={subPage.path} className="px-4">
																{subPage.name}
															</Link>
														</div>
													);
												})}
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								);
							}
						})}
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</div>
	);
};

export default Header;
