import React from "react";
import { motion } from "framer-motion";

const ServiceCard = ({ heading, desc, icon }) => {
	return (
		<motion.div
			initial={{ y: 40 }}
			whileInView={{ y: 1 }}
			transition={{ type: "spring" }}
			className="servicecard d-flex flex-column align-items-center text-center gap-4 py-5 px-3 px-xl-5 bg-theme-white"
		>
			<h4 className="fw-bold">{heading}</h4>
			<p className="text-theme-gray">{desc}</p>
			<div className="servicecard-list-icon">
				<img alt="icon" src={icon} />
			</div>
		</motion.div>
	);
};

export default ServiceCard;
