export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	causes: "/causes",
	causeDetails: "/causes/causes-details",
	charityProjects: "/charity-projects",
	charityDetails: "/portfolio/charity-details",
	ourTeam: "/our-team",
	teamDetails: "/our-team/team-details",
	faq: "/faq",
	testimonial: "/testimonial",
	events: "/events",
	eventDetails: "/events/event-details",
	blogs: "/blogs",
	blogDetails: "/blogs/blog-details",
	donate: "/donate",
	contactPage: "/contact-us",
	errorPage: "/404",
	comingSoon: "/coming-soon",
};
