import React from "react";
import { Col, Row } from "react-bootstrap";
import Img1 from "../../assets/images/brand/logo_01.png";
import Img2 from "../../assets/images/brand/logo_02.png";
import Img3 from "../../assets/images/brand/logo_03.png";
import Img4 from "../../assets/images/brand/logo_04.png";
import Img5 from "../../assets/images/brand/logo_05.png";
import Img6 from "../../assets/images/brand/logo_06.png";
import { motion } from "framer-motion";

const ClientBannerSection = ({ noHead }) => {
	return (
		<div className="blog-section d-flex flex-column align-items-center justify-content-center w-100">
			<div
				className={`section-padding py-5 text-theme-secondary w-100 container-theme d-flex flex-column align-items-center gap-5 ${
					noHead && "bg-theme-white"
				}`}
			>
				{!noHead && (
					<motion.h3
						initial={{ x: -50 }}
						whileInView={{ x: 1 }}
						transition={{ type: "spring" }}
						className="text-center"
					>
						We collaborate With <span className="text-theme-primary">250+</span>{" "}
						<br /> NGO Organization
					</motion.h3>
				)}
				<motion.div
					initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ type: "spring" }}
					className="w-100 d-flex align-items-center justify-content-center"
				>
					<Row className="w-100 p-4 bg-theme-primary">
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img1} alt="img" />
						</Col>
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img2} alt="img" />
						</Col>
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img3} alt="img" />
						</Col>
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img4} alt="img" />
						</Col>
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img5} alt="img" />
						</Col>
						<Col xs={6} md={4} lg={2}>
							<img className="img-fluid w-100" src={Img6} alt="img" />
						</Col>
					</Row>
				</motion.div>
			</div>
		</div>
	);
};

export default ClientBannerSection;
